/* eslint-disable import/prefer-default-export */
export const PACKAGE_STATUS = {
  PENDING: 'PENDING',
  RECEIVED: 'RECEIVED',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  PAID: 'PAID',
  DISPATCHED: 'DISPATCHED',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED',
};

export const STRIPE_PUB_KEY = 'pk_live_51Hz5tADEehMmeJwE7p3oVSeFAJJzhHMUqIbhTFog1QS2iqT8P5Klw6c6QJx47mcjWWAEWcKsiSpVN8M17ryXdlRP00V4aVkb4j';

export const ADMIN_EMAIL = 'honduship@honduship.com'

export const API_URL = "https://us-central1-honduship-digital.cloudfunctions.net/api"

export const CITY_STATES = [
  "Atlantida, La Masica",
  "Atlantida, Jutiapa",
  "Atlantida, Tela",
  "Atlantida, La Ceiba",
  "Atlantida, Arizona",
  "Atlantida, Esparta",
  "Atlantida, San Francisco",
  "Atlantida, Corozal",
  "Atlantida, Triunfo de la Cruz",
  "Atlantida, El Porvenir",
  "Atlantida, Sambo Creek",
  "Atlantida, Nueva Armenia",
  "Atlantida, Tornabe",
  "Choluteca, Morolica",
  "Choluteca, Namasigue",
  "Choluteca, Orocuina",
  "Choluteca, Apacilagua",
  "Choluteca, Santa Ana de Yusguare",
  "Choluteca, San Marcos de Colón",
  "Choluteca, San José",
  "Choluteca, San Isidro",
  "Choluteca, San Antonio de Flores",
  "Choluteca, Pespire",
  "Choluteca, Choluteca",
  "Choluteca, Concepción de María",
  "Choluteca, Duyure",
  "Choluteca, El Corpus",
  "Choluteca, El Triunfo",
  "Choluteca, Marcovia",
  "Colon, Guadalupe",
  "Colon, San Antonio",
  "Colon, Rio Esteban",
  "Colon, Limón",
  "Colon, Sabá",
  "Colon, Santa Fe",
  "Colon, Tocoa",
  "Colon, Bonito Oriental",
  "Colon, Trujillo",
  "Colon, Santa Rosa de Aguán",
  "Colon, Sonaguera",
  "Colon, Balfate",
  "Colon, Iriona",
  "Comayagua, La Trinidad",
  "Comayagua, Lamaní",
  "Comayagua, La libertad",
  "Comayagua, Humuya",
  "Comayagua, Comayagua",
  "Comayagua, Ajuterique",
  "Comayagua, El Rosario",
  "Comayagua, Minas de Oro",
  "Comayagua, Esquías",
  "Comayagua, Ojos de Agua",
  "Comayagua, San Jerónimo (Honduras)",
  "Comayagua, San José de Comayagua",
  "Comayagua, San José del Potrero",
  "Comayagua, San Luis",
  "Comayagua, San Sebastián",
  "Comayagua, Siguatepeque",
  "Comayagua, Villa de San Antonio",
  "Comayagua, Las Lajas",
  "Comayagua, Taulabé",
  "Comayagua, Meambar",
  "Comayagua, Lejamani",
  "Copan, San Nicolás",
  "Copan, Santa Rosa de Copán",
  "Copan, Cabañas",
  "Copan, Concepción",
  "Copan, Copán Ruinas",
  "Copan, Corquín",
  "Copan, Cucuyagua",
  "Copan, Dolores",
  "Copan, Dulce Nombre",
  "Copan, El Paraíso",
  "Copan, Florida",
  "Copan, La Jigua",
  "Copan, La Unión",
  "Copan, Nueva Arcadia",
  "Copan, San Agustín",
  "Copan, San Antonio",
  "Copan, San Jerónimo",
  "Copan, San José",
  "Copan, San Juan de Opoa",
  "Copan, San Pedro",
  "Copan, Santa Rita",
  "Copan, Trinidad de Copán",
  "Copan, Veracruz",
  "Cortes, San Manuel",
  "Cortes, San Francisco de Yojoa",
  "Cortes, Travesia",
  "Cortes, Cortes",
  "Cortes, San Pedro Sula",
  "Cortes, Choloma",
  "Cortes, Omoa",
  "Cortes, Pimienta",
  "Cortes, Puerto Cortés",
  "Cortes, Potrerillos",
  "Cortes, San Antonio de Cortés",
  "Cortes, Villanueva",
  "Cortes, Santa Cruz de Yojoa",
  "Cortes, La Lima",
  "El Paraiso, Yauyupe",
  "El Paraiso, Trojes",
  "El Paraiso, Yuscarán",
  "El Paraiso, Alauca",
  "El Paraiso, El Paraíso",
  "El Paraiso, Danlí",
  "El Paraiso, Güinope",
  "El Paraiso, Jacaleapa",
  "El Paraiso, Liure",
  "El Paraiso, Morocelí",
  "El Paraiso, Oropolí",
  "El Paraiso, Potrerillos",
  "El Paraiso, San Antonio de Flores",
  "El Paraiso, San Lucas",
  "El Paraiso, San Matías",
  "El Paraiso, Soledad",
  "El Paraiso, Teupasenti",
  "El Paraiso, Texiguat",
  "El Paraiso, Vado Ancho",
  "Francisco Morazan, Valle de Ángeles",
  "Francisco Morazan, Distrito Central",
  "Francisco Morazan, Alubarén",
  "Francisco Morazan, Cedros",
  "Francisco Morazan, Curarén",
  "Francisco Morazan, El Porvenir",
  "Francisco Morazan, Guaimaca",
  "Francisco Morazan, La Libertad",
  "Francisco Morazan, La Venta",
  "Francisco Morazan, Lepaterique",
  "Francisco Morazan, Maraita",
  "Francisco Morazan, Marale",
  "Francisco Morazan, Nueva Armenia",
  "Francisco Morazan, Ojojona",
  "Francisco Morazan, Orica",
  "Francisco Morazan, Reitoca",
  "Francisco Morazan, Sabanagrande",
  "Francisco Morazan, San Antonio de Oriente",
  "Francisco Morazan, San Buenaventura",
  "Francisco Morazan, San Ignacio",
  "Francisco Morazan, San Juan de Flores",
  "Francisco Morazan, San Miguelito",
  "Francisco Morazan, Santa Ana",
  "Francisco Morazan, Santa Lucía",
  "Francisco Morazan, Talanga",
  "Francisco Morazan, Tatumbla",
  "Francisco Morazan, Tegucigalpa",
  "Francisco Morazan, Villa de San Francisco",
  "Francisco Morazan, Vallecillo",
  "Gracias a Dios, Wampusirpe",
  "Gracias a Dios, Ramón Villeda Morales",
  "Gracias a Dios, Juan Francisco Bulnes",
  "Gracias a Dios, Ahuas",
  "Gracias a Dios, Brus Laguna",
  "Gracias a Dios, Puerto Lempira",
  "Intibuca, San Antonio",
  "Intibuca, La Esperanza",
  "Intibuca, Camasca",
  "Intibuca, Colomoncagua",
  "Intibuca, Concepción",
  "Intibuca, Dolores",
  "Intibuca, Intibucá",
  "Intibuca, Jesús de Otoro",
  "Intibuca, Magdalena",
  "Intibuca, Masaguara",
  "Intibuca, San Isidro",
  "Intibuca, San Juan",
  "Intibuca, San Marcos de la Sierra",
  "Intibuca, San Miguel Guancapla",
  "Intibuca, Santa Lucía",
  "Intibuca, Yamaranguila",
  "Intibuca, San Francisco de Opalaca",
  "Islas de la Bahia, José Santos Guardiola",
  "Islas de la Bahia, Utila",
  "Islas de la Bahia, Roatán",
  "Islas de la Bahia, Guanaja",
  "La Paz, Cane",
  "La Paz, Santa Elena",
  "La Paz, Santa María",
  "La Paz, Santiago de Puringla",
  "La Paz, Yarula",
  "La Paz, San Pedro de Tutule",
  "La Paz, Santa Ana",
  "La Paz, San Juan",
  "La Paz, San José",
  "La Paz, San Antonio del Norte",
  "La Paz, Opatoro",
  "La Paz, Mercedes de Oriente",
  "La Paz, Marcala",
  "La Paz, Lauterique",
  "La Paz, Chinacla",
  "La Paz, Guajiquiro",
  "La Paz, La Paz",
  "La Paz, Aguanqueterique",
  "La Paz, Cabañas",
  "Lempira, Talgua",
  "Lempira, Tambla",
  "Lempira, Tomalá",
  "Lempira, Valladolid",
  "Lempira, San Marcos de Caiquín",
  "Lempira, Virginia",
  "Lempira, Gracias",
  "Lempira, Belén",
  "Lempira, Candelaria",
  "Lempira, Cololaca",
  "Lempira, Erandique",
  "Lempira, Gualcince",
  "Lempira, Guarita",
  "Lempira, La Campa",
  "Lempira, La Iguala",
  "Lempira, Las Flores",
  "Lempira, La Unión",
  "Lempira, La Virtud",
  "Lempira, Lepaera",
  "Lempira, Mapulaca",
  "Lempira, Piraera",
  "Lempira, San Andrés",
  "Lempira, San Francisco",
  "Lempira, San Juan Guarita",
  "Lempira, San Manuel Colohete",
  "Lempira, San Rafael",
  "Lempira, San Sebastián",
  "Lempira, Santa Cruz",
  "Ocotepeque, Mercedes",
  "Ocotepeque, San Fernando",
  "Ocotepeque, San Francisco del Valle",
  "Ocotepeque, San Jorge",
  "Ocotepeque, San Marcos",
  "Ocotepeque, Santa Fe",
  "Ocotepeque, Sensenti",
  "Ocotepeque, Sinuapa",
  "Ocotepeque, Lucerna",
  "Ocotepeque, La Labor",
  "Ocotepeque, La Encarnación",
  "Ocotepeque, Fraternidad",
  "Ocotepeque, Dolores Merendón",
  "Ocotepeque, Concepción",
  "Ocotepeque, Belén Gualcho",
  "Ocotepeque, Ocotepeque",
  "Olancho, Guayape",
  "Olancho, Juticalpa",
  "Olancho, Campamento",
  "Olancho, Catacamas",
  "Olancho, Concordia",
  "Olancho, Dulce Nombre de Culmí",
  "Olancho, El Rosario",
  "Olancho, Esquipulas del Norte",
  "Olancho, Gualaco",
  "Olancho, Guarizama",
  "Olancho, Guata",
  "Olancho, Jano",
  "Olancho, La Unión",
  "Olancho, Mangulile",
  "Olancho, Manto",
  "Olancho, Salamá",
  "Olancho, San Esteban",
  "Olancho, San Francisco de Becerra",
  "Olancho, San Francisco de la Paz",
  "Olancho, Santa María del Real",
  "Olancho, Silca",
  "Olancho, Yocón",
  "Olancho, Patuca",
  "Partamento, Municipio",
  "Santa Barbara, Gualala",
  "Santa Barbara, Ilama",
  "Santa Barbara, Las Vegas",
  "Santa Barbara, Macuelizo",
  "Santa Barbara, Naranjito",
  "Santa Barbara, Nuevo Celilac",
  "Santa Barbara, Nueva Frontera",
  "Santa Barbara, Petoa",
  "Santa Barbara, Protección",
  "Santa Barbara, Quimistán",
  "Santa Barbara, San Francisco de Ojuera",
  "Santa Barbara, El Níspero",
  "Santa Barbara, San Luis",
  "Santa Barbara, San Marcos",
  "Santa Barbara, San Nicolás",
  "Santa Barbara, San Pedro Zacapa",
  "Santa Barbara, San Vicente Centenario",
  "Santa Barbara, Santa Rita",
  "Santa Barbara, Trinidad",
  "Santa Barbara, San José de las Colinas",
  "Santa Barbara, Chinda",
  "Santa Barbara, Concepción del Sur",
  "Santa Barbara, Concepción del Norte",
  "Santa Barbara, Ceguaca",
  "Santa Barbara, Azacualpa",
  "Santa Barbara, Atima",
  "Santa Barbara, Arada",
  "Santa Barbara, Santa Bárbara",
  "Valle, Nacaome",
  "Valle, Alianza",
  "Valle, Amapala",
  "Valle, Aramecina",
  "Valle, Caridad",
  "Valle, Goascorán",
  "Valle, Langue",
  "Valle, San Francisco de Coray",
  "Valle, San Lorenzo",
  "Yoro, Victoria",
  "Yoro, Yoro",
  "Yoro, Yorito",
  "Yoro, Arenal",
  "Yoro, El Negrito",
  "Yoro, El Progreso",
  "Yoro, Jocón",
  "Yoro, Morazán",
  "Yoro, Olanchito",
  "Yoro, Santa Rita",
  "Yoro, Sulaco"
]