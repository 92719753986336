import React, {
  createContext,
  useEffect,
  useReducer,
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import appService from 'src/services/appService';
import userService from 'src/services/userService';
const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithCustomToken: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  reloadUserData: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithCustomToken = (token) => {
    return firebase.auth().signInWithCustomToken(token)
  }

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  const reloadUserData = async () => {
    const response = await userService.getUserById(firebase.auth().currentUser.uid);
    const lockboxAddresses = await appService.getLockboxAddresses();
    const user = firebase.auth().currentUser
    if (response) {
      //setUser(response);
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            id: user.uid,
            email: user.email,
            displayName: response.firstName || user.email,
            isVerified: user.emailVerified,
            lockboxAddresses: lockboxAddresses,
            ...response
          }
        }
      });
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      try {
        if (user) {
          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          const response = await userService.getUserById(user.uid);
          const lockboxAddresses = await appService.getLockboxAddresses();
          if (response) {
            //setUser(response);
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  email: user.email,
                  displayName: response.firstName || user.email,
                  isVerified: user.emailVerified,
                  lockboxAddresses: lockboxAddresses,
                  ...response
                }
              }
            });
          }
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch(err) {
        console.log('error', err);
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithCustomToken,
        signInWithGoogle,
        logout,
        reloadUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;