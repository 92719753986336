import { PACKAGE_STATUS } from "src/constants";
import { firestore } from "../lib/firebase";

class AppService {

    getLockboxAddresses = async () => {
        return new Promise((resolve, reject) => {
            firestore
            .collection('lockboxAddresses')
            .orderBy('displayOrder')
            .get()
            .then(snapshot => {
                var addresses = snapshot.docs.map(d => d.data())
                resolve(addresses)
            })
            .catch(error => {
                reject(error);
            });
        })
    }

    getBannerImages = async () => {
        return new Promise((resolve, reject) => {
            firestore
            .collection('appSettings')
            .doc("bannerImages")
            .get()
            .then(snapshot => {
                var images = snapshot.data().images;
                resolve(images)
            })
            .catch(error => {
                reject(error);
            });
        })
    }

    verifyAdminToken = async (token) => {
        return new Promise((resolve, reject) => {
            if(token === null || token === "")
                resolve(false)
            else{
                firestore
                .collection('appSettings')
                .doc("adminToken")
                .get()
                .then(snapshot => {
                    var dbToken = snapshot.data().id;
                    if(token === dbToken){
                        firestore
                        .collection('appSettings')
                        .doc("adminToken")
                        .update({id: null})
                        .then(snapshot => {
                            resolve(true);
                        })
                    }
                    else{
                        resolve(false)
                    }
                })
                .catch(error => {
                    reject(error);
                });
            }
        })
    }
    
    getLockboxId = async (data) => {
        return new Promise((resolve, reject) => {
            firestore
            .collection('appSettings')
            .doc("lockboxIds")
            .get()
            .then(snapshot => {
                var oldId = snapshot.data().id;
                firestore
                    .collection('appSettings')
                    .doc("lockboxIds")
                    .update({id: parseInt(oldId) + 1})
                    .then(snapshot => {
                        resolve(oldId);
                    })
            })
            .catch(error => {
                reject(error);
            });
        })
    }

    addContactUsMessage = async(message) => {
        return new Promise((resolve, reject) => {
            firestore
                .collection('queries')
                .add(message)
                .then(snapshot => {
                    resolve()
                })
                .catch(err => reject(err))
        })
    }

    addLog = async(log) => {
        return new Promise((resolve, reject) => {
            firestore
                .collection('logs')
                .add(log)
                .then(snapshot => {
                    resolve()
                })
                .catch(err => reject(err))
        })
    }

    fixData = () => {
        firestore.collection('userProfile')
            .get()
            .then(snapshot => {
              snapshot.docs.forEach(doc => {
                    firestore.collection('packageAlerts').where("addedBy","==",doc.id).where("totalFee","!=","0").where("status","in",[PACKAGE_STATUS.PAID, PACKAGE_STATUS.DISPATCHED,PACKAGE_STATUS.OUT_FOR_DELIVERY,PACKAGE_STATUS.DELIVERED]).get().then(snapshot => {
                        var noOfDocs = snapshot.docs.length
                        firestore.collection("userProfile").doc(doc.id).update({
                            noOfTransactions: noOfDocs
                        })
                    })
              })
            })
    }

}

const appService = new AppService();

export default appService;
