import React from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/FirebaseAuthContext';
import routes, { renderRoutes } from 'src/routes';
import { createTheme } from 'src/theme';
import { X as CloseIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  Button
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    language: settings.language,
    theme: "LIGHT"
  });

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  
  return (
    <ThemeProvider theme={theme}>
    <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
            dense
            maxSnack={3}
            hideIconVariant={false}
            ref={notistackRef}
            action={key => (
              <Button onClick={onClickDismiss(key)}>
                <CloseIcon />
              </Button>
            )}
          >
            <Router history={history}>
              <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  <GoogleAnalytics />
                  <CookiesNotification />
                    {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>       
  );
};

export default App;