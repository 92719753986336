import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";

// real project
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBhEP3_TD5IAjBAT7jtgVogIuJePGmZtlM",
  authDomain: "honduship-digital.firebaseapp.com",
  projectId: "honduship-digital",
  storageBucket: "honduship-digital.appspot.com",
  messagingSenderId: "177498827326",
  appId: "1:177498827326:web:bba830a4a5bf8ba2593f6f",
  measurementId: "G-Q2JG45C4C4",
};

firebase.initializeApp(firebaseConfig);
export default firebase;
export const firebaseRef = firebase;
export const auth = firebase.auth;
export const firestore = firebase.firestore();
export const storageRef = firebase.storage().ref();
