import React from 'react';

function Footer() {
     
  return (
    <>
        <div className="footer-bottom">
            <div className="auto-container">
                <div className="row m-0 align-items-center justify-content-between">
                    <div className="copyright-text">Copyright © {new Date().getFullYear()} <span className="href"> Honduship.</span> All Rights Reserved.</div>
                    <ul className="menu">
                       {/* <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy </a></li>
  <li><a href="#">  Sitemap</a></li> */}
                    </ul>
                </div>            
            </div>
        </div>
    </>
  );
}

export default Footer;
