import React from 'react';
import strings from 'src/utils/localization';
import { Link } from "react-router-dom";

function Footer() {

  return (
    <>
        <footer className="main-footer">
            <div className="upper-box">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="widget contact-widget style-two">
                                <h4>{strings.haveQuestions} <br /> {strings.contactOurTeam}</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="wrapper-box">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-calling"></span></div>
                                                <div className="text"><strong>{strings.phone}</strong><a href="tel:+17185695339">(718) 569‐5339</a></div>
                                            </div>
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-mail"></span></div>
                                                <div className="text"><strong>{strings.emailAddress}</strong><a href="mailto:soporte@honduship.com">soporte@honduship.com</a></div>
                                            </div>
                                            <ul className="social-icon">
                                                <li><a href="https://www.facebook.com/honduship"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="https://www.twitter.com/honduship"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="https://www.instagram.com/honduship"><i className="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><span className="flaticon-mail"></span></div>
                                            <div>
                                                <div className="text"><strong>{strings.monFri}</strong>08.00 am to 9.00pm</div>
                                                <div className="text"><strong>{strings.sat}</strong>10.00 am to 4.00pm</div>
                                                <div className="text"><span>{strings.sun}</span></div>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-6">
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="widget instagram-widget">
                                        <h4 className="widget_title">{strings.usefulLinks}</h4>
                                        <div className="wrapper-box">
                                            <div className="image">
                                                <Link to="/terms-and-conditions">{strings.termsConditions}</Link>
                                            </div>
                                           {/* <div className="image">
                                                <Link to="#">{strings.prohibitedItems}</Link>
                                            </div>
                                            <div className="image">
                                                <Link to="#">{strings.dangerousItems}</Link>
                                            </div>
                                            <div className="image">
                                                <a href="/#faq">{strings.frequentQuestions}</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>               
        </footer>

        <div className="footer-bottom">
            <div className="auto-container">
                <div className="row m-0 align-items-center justify-content-between">
                    <div className="copyright-text">Copyright © {new Date().getFullYear()} <span className="href"> Honduship.</span> All Rights Reserved.</div>
                    <ul className="menu">
                       {/* <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy </a></li>
<li><a href="#">  Sitemap</a></li> */}
                    </ul>
                </div>            
            </div>
        </div>
    </>
  );
}

export default Footer;
