import React, { useEffect, useState } from "react";
import strings from "src/utils/localization";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { useHistory } from 'react-router-dom';

function Header() {
  const { user, logout } = useAuth()
  const history = useHistory();

  const [languageImageSrc, setLanguageImageSrc] = useState();
  const [selectedLanguage] = useState(() => {
    const localLanguage =
      localStorage.getItem("language") !== null
        ? localStorage.getItem("language")
        : "es";

    return localLanguage;
  });

  const handleLanguageChange = () => {
    console.log(selectedLanguage);
    if (selectedLanguage === "en") {
      strings.setLanguage("es");
      localStorage.setItem("language", "es");
      window.location.reload(false);
    } else if (selectedLanguage === "es") {
      strings.setLanguage("en");
      localStorage.setItem("language", "en");
      window.location.reload(false);
    }
  };

  useEffect(() => {
    if (selectedLanguage === "en") {
      setLanguageImageSrc("/assets/images/resource/flags/en.jpg");
    } else {
      setLanguageImageSrc("/assets/images/resource/flags/es.png");
    }
    window.initScroll();
    window.initMobileMenu();
    strings.setLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div id="home"></div>
      <header className="main-header header-style-one onepage-header">
        <div className="header-top">
          <div className="auto-container">
            <div className="inner-container">
              <div className="left-column">
                <ul className="social-icon">
                  <li>
                    <a href="https://www.facebook.com/honduship">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/honduship">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/honduship">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="right-column">
                <div className="phone-number">
                  <i className="fab fa-whatsapp"></i>
                  <a href="tel:+17185695339">(718) 569‐5339</a>
                </div>
                <div className="phone-number">
                  <i className="flaticon-mail"></i>
                  <a href="mailto:soporte@honduship.com">soporte@honduship.com</a>
                </div>
                <div className="language">
                  <span className="icon">
                    <img src={languageImageSrc} alt="language" />
                  </span>
                  <form action="#" className="language-switcher">
                    <select
                      defaultValue={selectedLanguage}
                      onChange={handleLanguageChange}
                      className="languageSelect"
                    >
                      <option value="en">En</option>
                      <option value="es">Es</option>
                    </select>
                  </form>
                </div>
                <div className="top-bar-right-info">
                    <div className="sign-in">
                      {
                        user && 
                        <div>Welcome <Link to="/app/account">{user.displayName}</Link>, <span className="logout" onClick={handleLogout} to="/logout">
                          Logout
                        </span> </div>
                      }
                      {
                        !user && 
                        <>
                          <Link to="/login">
                            <i className="flaticon-delivery-man"></i>
                            {strings.signIn}
                          </Link>&nbsp;/&nbsp;
                          <Link to="/register">
                            {strings.signUp}
                          </Link>
                        </> 
                      }
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="onepage-menu">
          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                <div className="logo-box">
                  <div className="logo">
                    <Link to="/">
                      <img src="/assets/images/logo.png" alt="" />
                    </Link>
                  </div>
                </div>
                <div className="right-column">
                  <div className="nav-outer">
                    <select onChange={(e)=> {window.location = e.target.value}} defaultValue="" className="languageSelect d-md-none">
                      <option value="" disabled hidden>{strings.signIn}</option>
                      <option value="/login">{strings.signIn}</option>
                      <option value="/register">{strings.signUp}</option>
                    </select>
                    <div className="mobile-nav-toggler">
                      <img src="assets/images/icons/icon-bar-2.png" alt="" />
                    </div>
                    <nav className="main-menu navbar-expand-md navbar-light">
                      <div
                        className="collapse navbar-collapse show clearfix scroll-nav"
                        id="navbarSupportedContent"
                      >
                        <ul className="navigation">
                          <li>
                            <a href="/#howItWorks">{strings.howItWorks}</a>
                          </li>
                          <li className="">
                            <a href="/#faq">{strings.faq}</a>
                          </li>
                          <li className="">
                            <a href="/#contact">{strings.contact}</a>
                          </li> 
                          <li className={window.location.href.includes("/shop") ? "current" : ""}>
                            <a href="/shop">{strings.shop}</a>
                          </li>
                          <li className={window.location.href.includes("/blog") ? "current" : ""}>
                            <a href="/blog">{strings.blog}</a>
                          </li>
                          <li className="">
                            <a href="/app/dashboard">{strings.lockbox}</a>
                          </li>
                          <li className="">
                            <a href="/app/packages/alert">{strings.alertPurchase}</a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="navbar-right-info">
                    <div className="sign-in">
                      {
                        user && 
                        <div>Welcome <Link to="/app/account">{user.displayName}</Link>, <span className="logout" onClick={handleLogout} to="/logout">
                          Logout
                        </span> </div>
                      }
                      {
                        !user && 
                        <>
                          <Link to="/login">
                            <i className="flaticon-delivery-man"></i>
                            {strings.signIn}
                          </Link>&nbsp;/&nbsp;
                          <Link to="/register">
                            {strings.signUp}
                          </Link>
                        </> 
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn">
            <span className="icon flaticon-remove"></span>
          </div>

          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index.html">
                <img src="/assets/images/logo.png" alt="" title="" />
              </a>
            </div>
            <div className="menu-outer"></div>
            <div>
              {
                user && 
                <div className="logout-mobile" >Welcome <Link to="/app/account">{user.displayName}</Link>,  </div>
              }
              {
                user && 
                <div className="logout-mobile" onClick={handleLogout}>
                    Logout
                </div>
              }
              <div className="sign-in">
                {
                  !user && 
                  <>
                    <div className="logout-mobile">
                      <Link to="/login">
                        <i className="flaticon-delivery-man"></i>
                        {strings.signIn}
                      </Link> 
                    </div>
                    <div className="logout-mobile">
                      <Link to="/register">
                        <i className="flaticon-delivery-man"></i>
                        {strings.signUp}
                      </Link>
                    </div>
                  </>
                }    
              </div>
            </div>
          </nav>
        </div>

        <div className="nav-overlay">
          <div className="cursor"></div>
          <div className="cursor-follower"></div>
        </div>
      </header>
    </>
  );
}

export default Header;
