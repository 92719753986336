import { auth, firestore, firebaseRef } from 'src/lib/firebase';
import { getLoggedInUserId } from 'src/utils/settings';

class UserService {

  updateTotalDollarsSpent = (totalSpent, totalWeight) => 
    new Promise((resolve, reject) => {
      firestore.collection("userProfile").doc(getLoggedInUserId).update({
        totalDollarsSpent: firebaseRef.firestore.FieldValue.increment(totalSpent),
        totalPoundsPurchased: firebaseRef.firestore.FieldValue.increment(totalWeight)
      }).then(() => resolve())
      .catch(error => {
        console.log(error)
        reject(error);
      });
    })

  getStripeCustomerId = () => 
    new Promise((resolve, reject) => {
      firestore
        .collection('stripe_customers')
        .doc(getLoggedInUserId())
        .get()
        .then(snapshot => {
          resolve(snapshot.data().customer_id);
        })
        .catch(error => {
          console.log(error)
          reject(error);
        });
    });

  getUserById = userId =>
    new Promise((resolve, reject) => {
      firestore
        .collection('userProfile')
        .where('id', '==', userId)
        .get()
        .then(snapshot => {
          resolve(snapshot.docs[0]?.data());
        })
        .catch(error => {
          reject(error);
        });
    });
  
  updateUserGeneralInformation = (userProfile) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection('userProfile')
        .doc(userProfile.id)
        .set(userProfile)
        .then(() => {
          firebaseRef.auth().currentUser.updateProfile({
            displayName: userProfile.firstName + ' ' + userProfile.lastName,
          })
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  deleteUserAddress = (userId, address, addressesLength) => {
    return new Promise((resolve, reject) => {
      firestore.collection('userProfile')
        .doc(userId)
        .update({
          addresses: firebaseRef.firestore.FieldValue.arrayRemove(address),
          isProfileComplete: addressesLength - 1 !== 0
        })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  updateDefaultAddress = (userId, addresses) => {
    return new Promise((resolve, reject) => {
      firestore.collection('userProfile')
        .doc(userId)
        .update({
          addresses: addresses,
        })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  addUserAddress = (userId, address, lockboxId) => {
    return new Promise((resolve, reject) => {
      firestore.collection('userProfile')
        .doc(userId)
        .update({
          lockboxId: lockboxId,
          addresses: firebaseRef.firestore.FieldValue.arrayUnion(address),
          isProfileComplete: true,
        })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  
  updatePassword = (currentPassword, newPassword) =>
    new Promise((resolve, reject) => {
      this.reauthenticate(currentPassword)
        .then(() => {
          var user = auth().currentUser;
          user
            .updatePassword(newPassword)
            .then(() => {
              resolve();
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  
  reauthenticate = currentPassword => {
    var user = auth().currentUser;
    var cred = auth.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  };

}
const userService = new UserService();

export default userService;
