import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import ErrorFallback from './views/errors/UIError';
import {ErrorBoundary} from 'react-error-boundary'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                  >
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                  </ErrorBoundary>
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/account/cards',
        component: lazy(() => import('src/views/paymentCards'))
      },
      {
        exact: true,
        path: '/app/packages/alert',
        component: lazy(() => import('src/views/alertPackage'))
      },
      {
        exact: true,
        path: '/app/packages/dispatch',
        component: lazy(() => import('src/views/dispatchPackages'))
      },
      {
        exact: true,
        path: '/app/packages/history',
        component: lazy(() => import('src/views/packageHistory'))
      },
      {
        exact: true,
        path: '/app/packages',
        component: lazy(() => import('src/views/currentPackages'))
      },
      {
        exact: true,
        path: '/app/packages/:id/edit',
        component: lazy(() => import('src/views/alertPackage'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/dashboard'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/home'))
      },
      {
        exact: true,
        path: '/shop',
        component: lazy(() => import('src/views/shop'))
      },
      {
        exact: true,
        path: '/blog',
        component: lazy(() => import('src/views/blog'))
      },
      {
        exact: true,
        path: '/blog/:title',
        component: lazy(() => import('src/views/blog/details'))
      },
      {
        exact: true,
        path: '/blog/admin/:id',
        component: lazy(() => import('src/views/blog/draftDetails'))
      },
      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/register',
        component: lazy(() => import('src/views/auth/RegisterView'))
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/admin/token/login',
        component: lazy(() => import('src/views/auth/LoginTokenView'))
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/resetPassword',
        component: lazy(() => import('src/views/auth/ForgotPasswordView'))
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/terms-and-conditions',
        component: lazy(() => import('src/views/termsAndConditions'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
