
import jwtDecode from 'jwt-decode';
import { auth } from "src/lib/firebase";

export function restoreSettings() {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return settings;
}

export function storeSettings(settings) {
  localStorage.setItem('settings', JSON.stringify(settings));
}

export function getLoggedInUserId() {
  var user = auth().currentUser;
  if(user === null){
    var accessToken = localStorage.getItem('accessToken')
    const decoded = jwtDecode(accessToken);
    if(decoded === null){
      window.location = "/login"
    }
    return decoded.sub
  }
  
  return user.uid
}

export function getLoggedInUserEmail() {
  var user = auth().currentUser;
  if(user === null){
    var accessToken = localStorage.getItem('accessToken')
    const decoded = jwtDecode(accessToken);
    if(decoded === null){
      window.location = "/login"
    }
    return decoded.email
  }
  
  return user.email
}