import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    missionStatement:
      "Honduship is the leading company in cargo transportation from the United States to Honduras. We are a company based on the principles of integrity and responsibility. Giving value to our clients and working hard to maintain continuous improvement, which goes hand in hand with our commitment to provide an efficient, safe, and reliable service.",
    faq1: "How often do you ship out to Honduras?",
    faqa1: "Weekly",
    faq2: "How long does it take for my goods to arrive?",
    faqa2: "15-20 Days",
    faq3: "What parts of the country do you service?",
    faqa3:
      "We service customers in the New York Metropolitan area and we deliver to all accessible parts of Honduras. Customers outside of our area of service are welcomed to ship there goods to our office in the Bronx and we will gladly pack and ship it out to their desired destination in Honduras.",
    faq4: "What forms of payment do you accept?",
    faqa4: "Cash, Credit/Debit, Zelle, CashApp and PayPal",
    corporateValues:
      "To be recognized for our values and our service, becoming the leading reliable and timely cargo transportation company from the US to Honduras and a world-class innovator whose capabilities, management and leadership allow us to replicate the company's service to other regions. ",
    teamwork: "Teamwork",
    teamworkDesc:
      "We encourage participation among employees to achieve a common goal, sharing experience and knowledge.",
    communication: "Communication",
    communicationDesc:
      "We improve the competitiveness of the Organization, in order to achieve the objectives<br/> that have been initially proposed by promoting employee motivation, as well as commitment and involvement<br/> in corporate tasks, creating an inclusive work environment",
    innovation: "Innovation",
    innovationDesc:
      "It is a clear commitment to continuous improvement and the search for creative and efficient solutions<br/> so that they can guarantee the corporate sustainability of the Honduship Express company.",
    honesty: "Honesty",
    honestyDesc:
      "Honesty is our policy. We are faithful to ourselves, to our clients and we believe that this<br/> generates trust, credibility and lasting relationships by fulfilling our duties and obligations.",
    continuousImprovement: "Continuous <br/> improvement",
    improvementDesc:
      "We constantly strive to improve our services according to the highest standards and according to customer requirements.",
    integrity: "Integrity",
    integrityDesc:
      "We always keep our promises and treat everyone with respect, dignity and honesty. We adhere to the highest standards",
    safety: "Safety",
    safetyDec:
      "Creating an environment of safety in our work environment, <br/>since respect enhances trust and the latter is a guarantee of transparency and good communication.",
    efficiency: "Efficiency",
    efficiencyDesc:
      "Making shipments more optimally, quickly, and equally correctly,<br/> working under the standards and regulations that identify us as a leading company in the market.",
    valuesHeading: "Our Values",
    corporateValueHeading: "Corporate Values",
    faqHeading: "Frequently Asked Questions",
    getAnswers: "GET ANSWERS",
    missionStatementHeading: "Our Mission",

    // HEADER
    trackShipment: "Track Shipment",
    home: "Home",
    aboutUs: "About Us",
    ourProcess: "Our Process",
    products: "Products",
    faq: "FAQ",

    contact: "Contact Us",
    signIn: "Sign In",
    lockbox: "Lockbox",

    //BANNER TEXTS
    prev: "Prev",
    next: "Next",
    shipmentDeliverOnTime: "SHIPMENTS DELIVERED ON TIME",
    possibleHere: "IT'S POSSIBLE HERE AT HONDUSHIP",

    // Register Text
    identificationType: "Identification Type *",
    cedula: "Identification card",
    passport: "Passport",
    identificationNumber: "Identification number *",
    terms: "Accept our terms",
    seeTerms: "see terms and conditions",
    personalInformation: "personal Information",

    signUp: "Register",
    signupLockbox: "Sign up for our virtual lockbox",
    haveAccountLogin: "Have an account? Login",
    identificationRequired: "Identification document number is required",
    emailRequired: "Email is required",
    passwordRequired: "Password is required",
    passwordMinChar: "Must be at least 6 characters",
    passwordMatch: "Passwords must match",
    agreeTerms: 'You must agree to our terms and conditions',
    ruc: "RUC",
    foreignDoc: "Foreign Document",
    emailAddress: "Email Address",
    password: "Password",
    cPassword: "Confirm Password",
    iread: "I have read the",
    termsConditions: "Terms and Conditions",
    createAccount: "Create account",

    signInLockbox: "Sign in to your virtual lockbox",
    createNewAccount: "Create new account",
    validEmail: 'Must be a valid email',
    signInError: "Wrong username or password",

    registrationIncomplete: "Registration Incomplete",
    completeProfile: "Please complete your user profile before using the system.",
    clickHere: "Click here",
    toCompleteReg: "to complete registration.",
    myLockboxAdd: "My Lockbox Address",
    addressCopied: "Address copied to clipboard!",
    copyAddress: "Copy Address",
    lockboxAddressError: "Please complete your user profile before a virtual lockbox address can be assigned to you",
    haveQuery: "Have a query before sending a package?",
    seePackageStatus: "See status of your packages",
    informPurchases: "Inform us of your new purchases",
    alertPurchase: "Alert Purchase",
    authorizeDelivery: "Authorize packages for delivery",
    dispatchPackages: "Dispatch Packages",
    modifyProfile: "Modify your profile details",
    myProfile: "My Profile",
    seePackageHistory: "See history of your package delivery",
    packageHistory: "Packages History",

    error404: "Sorry this page isn’t available",
    cancel: "Cancel",

    general: "General",
    security: "Security",
    passwordUpdatedSuccess: 'Password updated successfully',
    changePassword: "Change Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    verificationEmailSuccess: 'Verification email sent successfully',
    dashboard: "Dashboard",
    account: "Account",
    settings: "Settings",
    verificationRequired: "Verification Required",
    sentVerificationEmail: "We have sent a verification email to your email address. Please use the link in that email to verify your email address.",
    didntReceiveEmail: "Didn't receive an email?",
    resendEmail: "Resend Email",
    myAddresses: "My Addresses",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNum: "Phone Number",
    mobileNum: "Mobile Number",
    dob: "Date of Birth",
    gender: "Gender",
    pleaseSelect: "Please Select",
    male: "Male",
    female: "Female",
    saveChanges: "Save Changes",
    firstNameRequired: 'First Name is required',
    lastNameRequired: 'Last Name is required',
    mobileNumberRequired: 'Mobile number is required',
    phoneNumberRequired: "Phone number is required",
    dobRequired: 'Date of birth is required',
    genderRequired: 'Gender is required',
    userProfileUpdatedSuccess: 'User profile updated successfully',
    addressUpdatedSuccess: 'Address updated successfully',
    addressInfo: "Address Information",
    addNewAddress: "Add New Address",
    streetAddress: "Full Address",
    cityAddress: "City/Municipality",
    stateAddress: "Department",
    streetRequired: "Full Address is required",
    recipientRequired: "Recipient name is required",
    cityRequired: "City/Municipality is required",
    stateRequired: "Department is required",
    addressAddedSuccess: 'New address added successfully',
    save: "Save",
    noAddressFound: "No addresses found",
    delete: "Delete",

    purhcaseDetails: "Purchase Details",
    packages: "Packages",
    purchaser: "Purchaser name",
    purhcaseDesc: "Purchase Description",
    carrier: "Carrier",
    carrierOther: "Carrier (Other)",
    tracking: "Tracking Number",
    storeBought: "Store from where you bought",
    purchaseValue: "Purchase Value in dollars ($)",
    categories: "Categories",
    receiptFile: "Receipt File",
    attachReceipt: "Attach Receipt File",
    purchaserRequired: "Purchaser name is required",
    purchaseDescReq: "Purchase description is required",
    carrierRequired: "Carrier name is required",
    trackingRequired: "Tracking number is required",
    storeRequired: "Store name is required",
    valueRequired: "Purchase value is required",
    categoryRequired: "Atleast one purchase category are required",
    packageAlertSuccess: 'Package has been alerted successfully',
    packageAlertDeleteSuccess: 'Package alert has been deleted successfully',
    packageAlertTerms: `I accept that all the information presented is 100% real and reliable, 
    which is why, under my responsibility, I authorize the dispatch of my packages 
    to be imported to Honduras.`,
    receiptPurchaseError: "Please attach the receipt of purchase",

    myPackages: "My Packages",
    currentPackages: "Current Packages",
    trackingId: "Tracking ID",
    packageDesc: "Package Description",
    weight: "Weight",
    value: "Value",
    status: "Status",
    dateAlerted: "Date Alerted",
    noPackagesFound: "No packages found",
    packageCost: "Shipping Fee",
    handlingFee: "Handling Fee",

    howItWorks: "Our Process",
    dontForgetAlert: "DON'T FORGET TO ALERT YOUR PURCHASE!",
    dontForgetAlertDesc: "Alert for new incoming package to our offices in preparation for delivery. <br/><br/> Alerting the purchase will avoid import delays and will allow you to easily identify the package.",
    doYourShopping: "DO YOUR SHOPPINNG",
    doShoppingDesc: "In the store of your preference in USA.",
    sendAndAlert: "SEND AND ALERT",
    sendAndAlertDesc: "To inform us of your package and import",
    receive: "RECEIVE",
    receiveDesc: "In any part of Honduras.",
    
    sendMessageSuccess: "Your message has been sent successfully",
    haveQuestions: "Do You Have Any Questions?",
    contactOurTeam: "Please Contact Our Team",
    phone: "Phone",
    monFri: "Mon - Friday",
    sat: "Saturday ",
    sun: "Sunday - Closed",
    usefulLinks: "Useful Links",
    importConditions: "Import Conditions",
    prohibitedItems: "Prohibited Items",
    dangerousItems: "Dangerous Items",
    frequentQuestions: "Frequent Questions",

    addCards: "My Payment Cards",
    addCardsDesc: "Modify cards for payment",
    last4: "Last 4 Card Digits",
    expDate: "Expiry Date",
    brand: "Card Type",
    checkoutSummary: "Checkout Summary",
    shippingCost: "Total Shipping Cost",
    totalPackages: "Total packages",
    checkoutDesc: "Lockbox Delivery Charges",
    pay: "Pay",
    totalWeight: "Total Weight",
    selected: "selected",
    authorizeBtn: "Authorize & Dispatch",
    howUseLockbox: "How to use Lockbox",
    welcome: "Welcome",
    rowsPerPage: "rows per page",
    noCardsFound: "No payment cards found",
    logout: "Logout",

    dropALine: "Drop A Line",
    hereForYou: "We’re Always Here for You",
    dontHesitateContact: "Please do not hesitate to contact us if you require further information about our lockbox.",
    message: "Message",
    sendMessage: "Send Message",

    banner1text: "Register your locker for FREE",
    banner2text: "Buy online and import with Honduship",
    registerNow: "Register Now",
    entry: "Entry",
    payNewCard: "Pay with new card",
    markDefault: "Mark Default",
    default: "Default",
    addNewCard: "Add New Card",
    completeProfileExternal: "Please complete your profile by specifying your identification information to obtain your virtual lockbox.",

    gotoDashboard: "Goto Dashboard",

    technology: "Technology",
    homeAppliances: "Home Appliances",
    clothing: "Clothing",
    shoes: "Shoes",
    other: "Other",
    welcomeDashboard: "Welcome to your dashboard,",
    welcomeDashboardLine2: "Your address can be seen on your left. Now you can do your shopping online using your address in the United States",
    
    //EMAILS
    //NEW REGISTRATION
    welcomeTitle: "Welcome to Honduship",
    welcomeSubtitle: "THANKS FOR SIGNING UP !",
    welcomeLine1: "Thank you for registering at www.honduship.com. <br/> Our intention is to provide you with a comprehensive import and purchase service. <br/><br/>",
    welcomeLine2: "Now you will have a mailbox address in New York & Florida, so you can send your purchases and we can finally import it. <br/> Your lockbox addresses are as follows:",
    welcomeLine3: "Please verify your email address by clicking the link below <br/>",
    verifyEmailBtn: "VERIFY YOUR EMAIL",
    welcomeSubject: "Welcome to Honduship",

    //NEW PACKAGE EMAIL
    newPackageEmailSubject: "Your package has been alerted",
    newPackageEmailTitle: "Package Alerted",
    newPackageEmailLine1: "Thank you for alerting us of your incoming shipment. <br/><br/>We have it registered with tracking number: ",
    newPackageEmailLine2: "It is shipping with: ",
    newPackageEmailLine3: "and it is described as ",
    newPackageEmailLine4: "We will contact you when we receive your package.",

    //Pay PACKAGE EMAIL
    payPackageEmailSubject: "Payment submitted for package",
    payPackageEmailTitle: "Package Payment submitted",
    payPackageEmailLine1: "Thank you for your payment for package with tracking number: ",
    payPackageEmailLine2: "You will be notified when your package has shipped.",

    //Reset Password
    resetPasswordEmailTitle: "Reset your password",
    resetPasswordEmailSubject: "Reset your password for Honduship",
    resetPasswordEmailLine1: "Please click on the following link to reset your password for Honduship account.",
    resetPasswordBtn: "Reset Password",

    forgotPassword: "Forgot Password",
    forgotPasswordDesc: "Enter your email to reset your password",

    paymentCardNotPresentError: "Please add a payment card before dispatching a package.",
    deliveryAddressNotPresentError: "Please add a delivery address before dispatching a package. Click <a class='clickHere' href='/app/account?v=add'>here</a> to add a delivery address.",
    selectAddressCard: "Please select a payment card to continue.",
    paymentCard: "Payment Card",
    deliveryAddress: "Delivery Address",
    deliveryAddressRequired: "Delivery address is required",

    actions: "Actions",

    //STATUSES
    pendingStatus: "PENDING",
    receivedStatus: 'RECEIVED',
    awaitingPaymentStatus: 'AWAITING PAYMENT',
    paidStatus: 'PAID',
    dispatchedStatus: 'DISPATCHED',
    outForDeliveryStatus: 'OUT FOR DELIVERY',
    deliveredStatus: 'DELIVERED',

    dateDelivered: "Delivered On",
    shop: "Shop",
    welcomeShop: "Welcome to our shop",
    welcomeShopLine2: "You can purchase your goods from the following stores and use your delivery address to send them to us. Your virtual lockbox address is as follows:",

    gotoShop: "Goto Shop",

    cardDeletedSuccess: "Payment card deleted successfully",
    cardAddedSuccess : "Payment card added successfully",
    resetPassword: "Reset my password!",

    cookiesNotification: "We use Cookies to ensure that we give you the best experience on our website. Read our",
    privacyPolicy: "Privacy Policy",
    iAgree: "I Agree",

    userTokenRequired: "Please enter a user token generated from the admin app",
    adminTokenRequired: "Please enter an admin token generated from the admin app",
    adminToken:"Admin Token",
    userToken: "User Token",
    tokenSignInError: "User token is invalid or has expired",
    paymentProcessError: "Your payment could not be processed. ",
    blog: "Blog",
    dontHaveLockbox: "Don't have a virtual lockbox?",
    maxDescription: "Description must be less than 500 characters.",
    totalFee: "Total Fee (Shipping + Handling)",
    promoCode: "Promo Code",
    apply:"Apply",
    promoCodeError: "The promo code is invalid or has expired.",
    promoCodeSuccess: "The promo code has been applied successfully for a discount of ",
    remove: "Remove",
    moreDetails: "More Details",
    recentPosts: "Recent Posts",
    prevPost: "PREV POST",
    nextPost: "NEXT POST",
    edit: "Edit",
    updatePurchase: "Update Purhcase",
    recipientName: "Recipient Name",
    maxTrackingNumDesc: "Tracking number should be less than 50 characters"
  },
  es: {
    missionStatement:
      "Honduship se proyecta como la empresa lider en transporte de carga de los Estados Unidos de America hacia Honduras. Somos una empresa basada en los principios de integridad y responsabilidad. Dandole valor a nuestros clientes y trabajando arduamente para mantener una mejora constante, lo cual va de la mano con nuestro compromiso de brindar un servicio eficiente, seguro, y confiable.",
    faq1: "¿Con qué frecuencia realiza envíos a Honduras? ",
    faqa1: "Semanal",
    faq2: "¿Cuánto tardan en llegar mis mercancías? ",
    faqa2: "15-20 días",
    faq3: "¿A qué partes del país prestan servicios? ",
    faqa3:
      "Damos servicio a clientes en el área metropolitana de Nueva York y realizamos envíos a todas las partes accesibles de Honduras. Los clientes fuera de nuestra área de servicio pueden enviar sus productos a nuestra oficina en el Bronx y con gusto los empacaremos y enviaremos a su destino deseado en Honduras.",
    faq4: "¿Qué formas de pago aceptan? ",
    faqa4: "Efectivo, Crédito / Débito, Zelle, CashApp y PayPal",
    corporateValues:
      "Ser reconocidos por nuestros valores, nuestro personal y nuestro servicio, convirtiéndonos en la compañía lider de transporte de carga maritima confiables y oportunas desde New York a Honduras e innovadora de clase mundial cuya capacidad, gestión y liderazgo, nos permita duplicar el servicio de la empresa constantemente.",
    teamwork: "Trabajo <br/> en equipo",
    teamworkDesc:
      "Fomentamos la participación entre los empleados para lograr<br/> un objetivo común, compartiendo experiencia y conocimiento.",
    communication: "Comunicación",
    communicationDesc:
      "Mejora la competitividad de la Organización, con el fin de conseguir los objetivos<br/> que se hayan propuesto inicialmente fomentando la motivación de los empleados,<br/> así como el compromiso y la implicación en las tareas corporativas, logrando crear un clima de trabajo integrador",
    innovation: "Innovación",
    innovationDesc:
      "Es un compromiso claro hacia la mejora continua y la búsqueda<br/> de soluciones creativas y eficientes para que puedan garantizar la sostenibilidad <br/>corporativa de la empresa Honduship Express",
    honesty: "Honestidad",
    honestyDesc:
      "La honestidad es nuestra política. Somos fieles a nosotros mismos,<br/> a nuestros clientes y creemos que este genera confianza, credibilidad y relaciones<br/> duraderas al cumplir con nuestros deberes y obligaciones.  ",
    continuousImprovement: "Mejora <br/> Constante",
    improvementDesc:
      "Nos esforzamos constantemente por mejorar nuestros servicios<br/> de acuerdo con los más altos estándares y según los requisitos del cliente.  ",
    integrity: "Integridad",
    integrityDesc:
      "Siempre cumplimos nuestras promesas y tratamos a todos con respeto,<br/> dignidad y honestidad. Nos apegamos a los más altos estándares ",
    safety: "Respecto",
    safetyDec:
      "creando un ambiente de seguridad en nuestro ámbito laboral siendo que el respeto<br/> potencia la confianza y esta última, es garantía de<br/> transparencia y buena comunicación.",
    efficiency: "Eficiencia",
    efficiencyDesc:
      "Realizando los envios de manera mas optima, rapida, e igualmente correcta,<br/> trabajando bajo los estandares y reglamentos que nos identifica<br/> como un empresa lider en el mercado. ",
    valuesHeading: "Nuestros Valores",
    corporateValueHeading: "Valores Corporativos",
    faqHeading: "Preguntas Frecuentes",
    getAnswers: "obtener respuestas",
    missionStatementHeading: "Nuestra Mision",

    //HEADER
    trackShipment: "Rastreo",
    home: "Servicios",
    aboutUs: "Sobre Nosotros",
    ourProcess: "Proceso Casillero",
    products: "Productos",
    faq: "Preguntas",
    contact: "Contacto",
    signIn: "Ingreso",
    lockbox: "Casillero",

    //BANNER TEXTS
    prev: "anter",
    next: "siguient",
    shipmentDeliverOnTime: "ENTREGAS EN EL TIEMPO PROMETIDO",
    possibleHere: "ES POSSIBLE CON HONDUSHIP",

    // Register Text
    identificationType: "Tipo de Identificación *",
    cedula: "Cedula",
    passport: "Pasaporte",
    identificationNumber: "Número de identificación *",
    confirmPassword: "Confirmar Contraseña *",
    minCharacter: "Mínimo 8 caracteres",
    repeatPassword: "repite la contraseña",
    terms: "Acepta nuestros términos",
    seeTerms: "ver terminos y condiciones",
    personalInformation: "informacion personal",
    registerAccount: "Registrar cuenta",

    signUp: "Registro",
    signupLockbox: "Registrate y empieza a comprar en linea.",
    haveAccountLogin: "¿Tienes una cuenta? Iniciar sesión",
    identificationRequired: "Se requiere el número de documento de identificación",
    emailRequired: "Correo electronico es requerido",
    passwordRequired: "Se requiere contraseña",
    passwordMinChar: "Debe tener como mínimo 6 caracteres",
    passwordMatch: "Las contraseñas deben coincidir",
    agreeTerms: "Debe aceptar nuestros términos y condiciones",
    ruc: "RUC",
    foreignDoc: "Documento extranjero" ,
    emailAddress: "Correo electrónico",
    password: "Contraseña",
    cPassword: "Confirmar Contraseña",
    iread: "He leído los",
    termsConditions: "Términos y condiciones",
    createAccount: "Crear una cuenta",

    signInLockbox: "Inicie sesión en el casillero",
    createNewAccount: "Crear una nueva cuenta",
    validEmail: "Debe ser un correo electrónico válido",
    signInError: "Nombre de usuario o contraseña incorrectos",

    registrationIncomplete: "Registro incompleto",
    completeProfile: "Por favor complete su perfil de usuario antes de usar el sistema",
    clickHere: "Haga clic aquí",
    toCompleteReg: "para completar el registro",
    myLockboxAdd: "Mi dirección de casillero",
    addressCopied: "¡Dirección copiada al portapapeles!" ,
    copyAddress: "Copiar dirección",
    lockboxAddressError: "Complete su perfil de usuario antes de que se le pueda asignar una dirección de caja de seguridad virtual",
    haveQuery: "¿Tiene alguna consulta antes de enviar un paquete?",
    seePackageStatus: "Ver el estado de sus paquetes",
    informPurchases: "Infórmanos de tus nuevas compras",
    alertPurchase: "Alerta de compra",
    authorizeDelivery: "Autorizar la entrega de paquetes",
    dispatchPackages: "Envío de paquetes",
    modifyProfile: "Modifica los detalles de tu perfil",
    myProfile: "Mi perfil",
    seePackageHistory: "Ver el historial de la entrega de su paquete",
    packageHistory: "Historial de paquetes",

    error404: "Lo siento, esta página no está disponible ",
    cancel: "Cancelar",

    general: "General",
    security: "Seguridad",
    passwordUpdatedSuccess: "Contraseña actualizada exitosamente",
    changePassword: "Cambiar la contraseña",
    currentPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    verificationEmailSuccess: "El correo electrónico de verificación se envió correctamente",
    dashboard: "Tablero",
    account: "Cuenta",
    settings: "Ajustes",
    verificationRequired: "Verificación requerida",
    sentVerificationEmail: "Hemos enviado un correo electrónico de verificación a su dirección de correo electrónico. Utilice el vínculo en ese correo electrónico para verificar su dirección de correo electrónico",
    didntReceiveEmail: "¿No recibió un correo electrónico?",
    resendEmail: "Reenviar email",
    myAddresses: "Mis direcciones",
    firstName: "Primer Nombre",
    lastName: "Apellido",
    phoneNum: "Número de teléfono",
    mobileNum: "Número de teléfono móvil",
    dob: "Fecha de nacimiento",
    gender: "Género",
    pleaseSelect: "Seleccione",
    male: "Masculino",
    female: "Femenino",
    saveChanges: "Guardar cambios",
    firstNameRequired: "Se requiere el primer nombre",
    lastNameRequired: "El apellido es obligatorio",
    mobileNumberRequired: "El número de móvil es obligatorio",
    phoneNumberRequired: "El número de telefono es obligatorio",
    dobRequired: "Se requiere fecha de nacimiento",
    genderRequired: "El género es obligatorio",
    userProfileUpdatedSuccess: "Perfil de usuario actualizado correctamente",
    addressUpdatedSuccess: "Dirección actualizada correctamente",
    addressInfo: "Datos del Domicilio",
    addNewAddress: "Agregar nueva dirección",
    streetAddress: "Dirección completa",
    cityAddress: "Ciudad / Municipio",
    stateAddress: "Departamento",
    streetRequired: "Se requiere la dirección completa",
    cityRequired: "Se requiere ciudad / municipio",
    stateRequired: "Se requiere departamento",
    addressAddedSuccess: "Nueva dirección agregada correctamente",
    save: "Guardar",
    noAddressFound: "No se encontraron direcciones",
    delete: "Borrar",

    purhcaseDetails: "Detalles de la compra",
    packages: "Paquetes",
    purchaser: "Nombre del comprador",
    purhcaseDesc: "Descripción de compra",
    carrier: "Transportador",
    carrierOther: "Transportista (otro)",
    tracking: "El número de rastreo",
    storeBought: "Tienda donde compraste",
    purchaseValue: "Valor de la compra en dolares ($)",
    categories: "Categorias",
    receiptFile: "Archivo de recibo",
    attachReceipt: "Adjuntar archivo de recibo",
    purchaserRequired: "Se requiere el nombre del comprador",
    purchaseDescReq: "Se requiere una descripción de la compra",
    carrierRequired: "El nombre del operador es obligatorio",
    trackingRequired: "Se requiere un número de rastreo",
    storeRequired: "El nombre de la tienda es obligatorio",
    valueRequired: "Se requiere valor de compra",
    categoryRequired: "Se requiere al menos una categoría de compra",
    packageAlertSuccess: "El paquete ha sido alertado correctamente",
    packageAlertTerms: "Acepto que toda la información presentada es 100% real y confiable, por lo que bajo mi responsabilidad autorizo ​​el envío de mis paquetes para ser importados a Honduras",
    receiptPurchaseError: "Adjunte el recibo de compra",

    myPackages: "Mis paquetes",
    currentPackages: "Paquetes actuales",
    trackingId: "ID de rastreo",
    packageDesc: "Descripción del paquete",
    weight: "Peso",
    value: "Valor",
    status: "Estado",
    dateAlerted: "Fecha de alerta",
    noPackagesFound: "No se encontraron paquetes",
    packageCost: "Gastos de envío",
    handlingFee: "Tasa de tramitación",

    howItWorks: "Nuestro Proceso",
    dontForgetAlert: "¡NO OLVIDES ALERTAR TU COMPRA!",
    dontForgetAlertDesc: "Alerta de nuevo paquete entrante a nuestras oficinas en preparación para la entrega. <br/><br/> Alertar la compra evitará retrasos en la importación y permitirá identificar el paquete con facilidad.",
    doYourShopping: "HAZ TUS COMPRAS",
    doShoppingDesc: "En la tienda de preferencia en USA.",
    sendAndAlert: "ENVÍA Y ALERTA",
    sendAndAlertDesc: "Para reconocer tu paquete e importar",
    receive: "RECIBE",
    receiveDesc: "En cualquier parte del Honduras.",
    sendMessageSuccess: "Tu mensaje ha sido enviado exitosamente",

    haveQuestions: "¿Tienes Alguna Pregunta?",
    contactOurTeam: "Póngase en Contacto Con Nuestro Equipo",
    phone: "Telefono",
    monFri: "Lunes a Viernes",
    sat: "Sabado  ",
    sun: "Sunday - Cerrado",
    usefulLinks: "Enlaces útiles",
    importConditions: "Condiciones de Importación",
    prohibitedItems: "Artículos Prohibidos",
    dangerousItems: "Artículos Peligrosos",
    frequentQuestions: "Preguntas Frecuentes",

    addCards: "Mis tarjetas de pago",
    addCardsDesc: "Modificar tarjetas para pago",
    last4: "Últimos 4 dígitos de la tarjeta",
    expDate: "Fecha de caducidad",
    brand: "Tipo de tarjeta",
    checkoutSummary: "Resumen de pago",
    shippingCost: "Costo total de envío",
    totalPackages: "Paquetes totales",
    checkoutDesc: "Cargos por entrega de caja de seguridad",
    pay: "Pagar",
    totalWeight: "Peso total",
    selected: "seleccionada",
    authorizeBtn: "Autorizar y enviar",
    howUseLockbox: "Como usar Lockbox",
    welcome: "Bienvenido",
    rowsPerPage: "filas por página",
    noCardsFound: "No se encontraron tarjetas de pago",
    logout: "Cerrar Sesión",

    dropALine: "Drop A Line",
    hereForYou: "Siempre Estamos Aqui para Ti",
    dontHesitateContact: "No dude en ponerse en contacto con nostros si necesita mas information sobre nuestro proceso",
    message: "Mensaje",
    sendMessage: "Enviar mensaje",

    banner1text: "Registra tu casillero GRATIS",
    banner2text: "Compra en linea e importa con Honduship",
    registerNow: "Regístrate ahora",
    entry: "Ingreso",
    payNewCard: "Pagar con tarjeta nueva",
    markDefault: "Marcar predeterminado",
    default: "Predeterminado",
    addNewCard: "Agregar nueva tarjeta",
    completeProfileExternal: "Por favor complete su perfil de usuario especificando su tipo y número de identificacion para obtener su casillero",
    technology: "Tecnología",
    homeAppliances: "Electrodomésticos",
    clothing: "Ropa",
    shoes: "Zapatos",
    other: "Otra",
    gotoDashboard: "Ir al Tablero",
    welcomeDashboard: "Bienvenido a tu casillero,",
    welcomeDashboardLine2: "Tu dirección y código se encuentra a la mano izquierda. Ahora puedes realizar tu compras en linea usando tu dirección asignada en los Estados Unidos.",

    //EMAILS
    //NEW REGISTRATION
    welcomeTitle: "Bienvenido a Honduship",
    welcomeSubtitle: "GRACIAS POR REGISTRARTE!",
    welcomeLine1: "Estimado cliente, gracias por registrarte en www.honduship.com <br/> Nuestra intención, es brindarte un servicio integral de importación y compras. <br/><br/>",
    welcomeLine2: "Ahora contarás con una dirección de casillero en Nueva York & Florido, para que envíes tus compras y finalmente podamos importarla. <br/> Las direcciones de su caja de seguridad son las siguientes:",
    welcomeLine3: "Verifique su dirección de correo electrónico haciendo clic en el enlace a continuación",
    verifyEmailBtn: "VERIFIQUE SU CORREO ELECTRÓNICO",
    welcomeSubject: "Bienvenido a Honduship",
    
    //NEW PACKAGE EMAIL
    newPackageEmailSubject: "Su paquete ha sido alertado",
    newPackageEmailTitle: "Paquete alertado",
    newPackageEmailLine1: "Gracias por alertarnos de su envío entrante. <br/> <br/> Lo tenemos registrado con número de seguimiento:",
    newPackageEmailLine2: "Se envía con: ",
    newPackageEmailLine3: "y se describe como ",
    newPackageEmailLine4: "Nos pondremos en contacto contigo cuando recibamos tu paquete.",
    
    //Pay PACKAGE EMAIL
    payPackageEmailSubject: "Pago enviado por paquete",
    payPackageEmailTitle: "Pago del paquete enviado",
    payPackageEmailLine1: "Gracias por el pago del paquete con número de seguimiento: ",
    payPackageEmailLine2: "Se le notificará cuando se haya enviado su paquete.",

    //Reset Password
    resetPasswordEmailTitle: "Restablecer su contraseña",
    resetPasswordEmailSubject: "Restablezca su contraseña para Honduship",
    resetPasswordEmailLine1: "Haga clic en el siguiente enlace para restablecer su contraseña para la cuenta de Honduship.",
    resetPasswordBtn: "Restablecer la contraseña",

    paymentCardNotPresentError: "Agregue una tarjeta de pago antes de enviar un paquete.",
    deliveryAddressNotPresentError: "Agregue una dirección de entrega antes de enviar un paquete. Haga clic <a href='/app/account?v=add' class='clickHere'> aquí </a> para agregar una dirección de entrega",
    selectAddressCard: "Seleccione una tarjeta de pago para continuar.",
    paymentCard: "Tarjeta de pago",
    deliveryAddress: "Dirección de entrega",
    deliveryAddressRequired: "Se requiere la dirección de entrega",
    actions: "Comportamiento",

    //STATUSES
    pendingStatus: "PENDING",
    receivedStatus: 'RECIBIÓ',
    awaitingPaymentStatus: 'EN ESPERA DE PAGO',
    paidStatus: 'PAGADO',
    dispatchedStatus: 'ENVIADO',
    outForDeliveryStatus: 'FUERA PARA ENTREGA',
    deliveredStatus: 'ENTREGADO',

    dateDelivered: "Entregado en",
    shop: "Tienda",
    welcomeShop: "Bienvenido a nuestra tienda",
    welcomeShopLine2: "Puede comprar sus productos en las siguientes tiendas y utilizar su dirección de entrega para enviárnoslos. Su dirección de caja de seguridad virtual es la siguiente:",

    gotoShop: "Ver Tienda",

    cardDeletedSuccess: "Tarjeta de pago eliminada correctamente",
    cardAddedSuccess : "Tarjeta de pago agregada correctamente",
    resetPassword: "Restablecer mi contraseña",
    forgotPassword: "Has olvidado tu contraseña",
    forgotPasswordDesc: "Ingrese su correo electrónico para restablecer su contraseña",

    cookiesNotification: "Este portal usa cookies para darle una mejor experiencia y puede recolectar datos personales. Para continuar acepte nuestro",
    privacyPolicy: "Aviso de Privacidad",
    iAgree: "Estoy de acuerdo",

    userTokenRequired: "Ingrese un token de usuario generado desde la aplicación de administración",
    adminTokenRequired: "Ingrese un token de administrador generado desde la aplicación de administrador",
    adminToken:"Token de administrador",
    userToken: "Token de usuario",
    tokenSignInError: "El token de usuario no es válido o ha caducado",
    paymentProcessError: "No se pudo procesar su pago. ",
    blog: "Blog",
    dontHaveLockbox: "¿No tienes una caja de seguridad virtual?",
    maxDescription: "La descripción debe tener menos de 500 caracteres.",
    totalFee: "Tarifa total (envío + manipulación)",
    promoCode: "Código Promocional",
    apply:"Solicitar",
    promoCodeError: "El código de promoción no es válido o ha caducado.",
    promoCodeSuccess: "El código de promoción se ha aplicado con éxito para un descuento de ",
    remove: "Eliminar",
    moreDetails: "Leer Mas",
    recentPosts: "Mensajes recientes",
    prevPost: "POST ANTERIOR",
    nextPost: "SIGUIENTE POST",
    packageAlertDeleteSuccess: 'La alerta de paquete se ha eliminado correctamente',
    edit: "Editar",
    updatePurchase: "Actualizar compra",
    recipientName: "Nombre del destinatario",
    recipientRequired: "Se requiere el nombre del destinatario",
    maxTrackingNumDesc: "El número de seguimiento debe tener menos de 50 caracteres."
  },
});

export default strings;
