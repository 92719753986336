import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/browser';
import { Button, Container } from '@material-ui/core';

function ErrorFallback({error}) {
  
  const [eventId, setEventId] = useState(0)

  const errorHandler = (error) => {
    Sentry.withScope((scope) => {
      scope.setExtras(error);
      const eventId = Sentry.captureException(error);
      setEventId(eventId);
    });
  }

  useEffect(() => {
    errorHandler();
  }, []);

  return (
    <Container maxWidth="sm" className="mainContainer">
            <div className="card my-5">
                <div className="card-header">
                    <p>
                    An error has occurred. Please contact support.{' '}
                    <span
                        style={{ cursor: 'pointer', color: '#0077FF' }}
                        onClick={() => {
                        window.location.reload();
                        }}
                    >
                        Reload this page
                    </span>{' '}
                    </p>
                </div>

                <div className="card-body">
                    <details className="error-details">
                    <summary>Click for error details</summary>
                    {error.message}
                    </details>
                </div>
                <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() =>
                        Sentry.showReportDialog({ eventId: eventId })
                    }
                >
                    Report feedback
                </Button>
                
            </div>
        </Container>
  )
}
export default ErrorFallback